import { getKevelGifIdByType } from '@/app/util/kevel'
import AdsContext from '@/context/ads'
import { useContext } from 'react'
import { isEmpty } from 'utils'
import { isValidActiveBanner } from '../app/util/banner'
import UAParserContext from '../context/ua-parser'
import AdBanner from './homepage/ad-banner'

const Banner = () => {
    const { deviceType } = useContext(UAParserContext)
    const { banner, isKevelAdEnabled } = useContext(AdsContext)

    if (isKevelAdEnabled('banner') || !banner || isEmpty(banner)) {
        return null
    }
    const isAdBanner = !isValidActiveBanner(banner)
    return isAdBanner ? (
        <AdBanner gif={banner} deviceType={deviceType} />
    ) : (
        <div
            className="md:w-desktopWidth h-[22vw] md:h-[96px]"
            onClick={() => {
                location.href = banner.link
            }}
        >
            <img
                className="h-full w-full object-cover"
                src={deviceType === 'desktop' ? banner.image : banner.image_mobile}
                alt={banner.alt_text}
            />
        </div>
    )
}

const placeholder = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
export const KevelBanner = () => {
    const { kevelClientData, isKevelAdEnabled } = useContext(AdsContext)
    const gifId = getKevelGifIdByType(kevelClientData, 'banner')
    return isKevelAdEnabled('banner') ? (
        <div className="md:w-desktopWidth h-[22vw] md:h-[96px]">
            <img
                src={gifId ? `https://media.giphy.com/media/${gifId}/giphy.webp` : placeholder}
                alt=""
                className="h-full w-full object-cover"
            />
        </div>
    ) : null
}

export default Banner
