import { giphyLocalUrl } from '@/app/util/env'
import { GiphyFetch } from '@giphy/js-fetch-api'
import { IGif } from '@giphy/js-types'
import { timeoutFetch } from 'utils/src/api/fetch'

export const MagicTag = { HomepageBanner: 'giphyhomepagebanner' } as const

/**
 * Represents the active banner object that comes from the django administrator app.
 */
export type ActiveBanner = {
    image: string
    image_mobile: string
    link: string
    is_active: boolean
    alt_text: string
}

/**
 * Checks if an object is a valid ActiveBanner at runtime.
 * @param obj - The object to be checked.
 * @returns A boolean indicating if the object is a valid ActiveBanner.
 */
export const isValidActiveBanner = (obj: any): obj is ActiveBanner => {
    return (
        obj &&
        typeof obj === 'object' &&
        typeof obj.image === 'string' &&
        typeof obj.image_mobile === 'string' &&
        typeof obj.link === 'string' &&
        typeof obj.is_active === 'boolean' &&
        typeof obj.alt_text === 'string'
    )
}

/**
 * Represents a banner object that can be either an ActiveBanner or a GIF banner.
 */
export type Banner = ActiveBanner | IGif

/**
 * Determines if a Gif includes advertisement content.
 * Specifically checks for 'bottle_data' property presence and non-emptiness.
 * @param {IGif} gif - Gif object to evaluate.
 * @returns {boolean} - True if 'bottle_data' is present and has data, false otherwise.
 */
export const containsAnAd = (gif: IGif): boolean => {
    return gif?.bottle_data && Object.keys(gif.bottle_data).length > 0
}

/**
 * Retrieves the active banner from the django administrator app.
 * @returns A Promise that resolves to the active banner object.
 * @throws An error if the active banner cannot be retrieved.
 */
export const getActiveBanner = async (): Promise<ActiveBanner> => {
    const f = await timeoutFetch({ url: `${giphyLocalUrl}/api/v2/banners/active` })
    const json = await f.json()
    return json
}

/**
 * Retrieves the MagicTag.HomepageBanner GIF from the Giphy API.
 * @param {GiphyFetch} giphyFetch - The GiphyFetch instance used to make the API request.
 * @returns {Promise<object>} - A Promise that resolves to the banner data object.
 * @throws An error if the GIF banner cannot be retrieved.
 */
export const getBannerFromGiphyAPI = async (giphyFetch: GiphyFetch): Promise<IGif> => {
    const { data } = await giphyFetch.search(MagicTag.HomepageBanner)
    return data[0]
}

/**
 * Retrieves the active banner or a random GIF banner from the Giphy API.
 * Returns an ad banner if it's an ad, otherwise returns the active banner.
 * @param giphyFetch - The GiphyFetch instance used to fetch the GIF banner.
 * @returns A Promise that resolves to either the ad banner (if it's an ad) or the active banner.
 * @throws An error if none of the banners can be retrieved or are valid.
 */
export const getBanner = async (giphyFetch: GiphyFetch) => {
    const [activeBannerResult, adBannerResult] = await Promise.allSettled([
        getActiveBanner(),
        getBannerFromGiphyAPI(giphyFetch),
    ])

    const adBanner = adBannerResult.status === 'fulfilled' ? adBannerResult.value : null
    const activeBanner = activeBannerResult.status === 'fulfilled' ? activeBannerResult.value : null

    if (adBanner && containsAnAd(adBanner)) {
        return { banner: adBanner, isAd: true }
    } else if (activeBanner) {
        return { banner: activeBanner, isAd: false }
    } else {
        throw new Error('Unable to retrieve a valid banner.')
    }
}
